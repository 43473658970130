var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('vx-card',[_c('div',{staticClass:"data-list-container",attrs:{"id":"data-list-list-view"}},[_c('vs-table',{ref:"table",attrs:{"max-items":_vm.itemsPerPage,"data":_vm.Model},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(item,indextr){return _c('tbody',{key:indextr,attrs:{"data":item}},[_c('vs-tr',{staticClass:"cursor-pointer hover:text-primary"},[_c('vs-td',[(item.Patient)?_c('p',{staticClass:"product-name font-medium truncate"},[_vm._v(" "+_vm._s(item.ReportTypeName)+" ")]):_vm._e()]),_c('vs-td',[(item.Date)?_c('p',{staticClass:"product-category"},[_vm._v(" "+_vm._s(item.Date.split("T")[0])+" ")]):_vm._e()]),_c('vs-td',[_c('imageLazy',{attrs:{"imageStyle":{
                  'border-radius': '20px',
                  width: '40px',
                  height: '40px',
                },"imageClass":'rounded-full',"src":_vm.baseURL + item.PatientImage,"placeHolderType":"patient"}}),_c('p',{staticClass:"product-name font-medium truncate",staticStyle:{"font":"normal normal normal 15px/20px Futura PT"}},[_vm._v(" "+_vm._s(item.PatientName)+" ")])],1),_c('vs-td',{staticStyle:{"background":"primary"}},[(
                  item.AttachPath != null || item.AttachPath != undefined
                )?_c('div',{staticClass:"m-3"},[_c('a',{attrs:{"href":_vm.baseURL + item.AttachPath,"download":"","target":"_blank"},on:{"click":function($event){return _vm.changeDocStatus(item)}}},[_c('vs-avatar',{staticStyle:{"background":"primary"},attrs:{"color":"primary","icon-pack":"feather","icon":"icon-download-cloud"}})],1),_c('p',{staticStyle:{"color":"#ffffff","font-size":"12px","opacity":"1","line-height":"30px"}},[_vm._v(" "+_vm._s(_vm.$t("Download"))+" ")])]):_vm._e()])],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(_vm._s(_vm.$t("PendingMedicalDocuments")))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("AppointmentDate")))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("Patient")))]),_c('vs-th')],1)],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }