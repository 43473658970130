<template>
  <div class="w-full">
    <vx-card>
      <div id="data-list-list-view" class="data-list-container">
        <vs-table ref="table" :max-items="itemsPerPage" :data="Model">
          <template slot="thead">
            <vs-th>{{
              $t("PendingMedicalDocuments")
            }}</vs-th>
            <vs-th>{{
              $t("AppointmentDate")
            }}</vs-th>
            <vs-th>{{ $t("Patient") }}</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-tr class="cursor-pointer hover:text-primary">
                <vs-td>
                  <p v-if="item.Patient" class="product-name font-medium truncate">
                    {{ item.ReportTypeName }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-category" v-if="item.Date">
                    {{ item.Date.split("T")[0] }}
                  </p>
                </vs-td>
                <vs-td>
                  <imageLazy :imageStyle="{
                    'border-radius': '20px',
                    width: '40px',
                    height: '40px',
                  }" :imageClass="'rounded-full'" :src="baseURL + item.PatientImage" placeHolderType="patient" />
                  <p class="product-name font-medium truncate" style="font: normal normal normal 15px/20px Futura PT">
                    {{ item.PatientName }}
                  </p>
                </vs-td>
                <vs-td style="background: primary">
                  <div class="m-3" v-if="
                    item.AttachPath != null || item.AttachPath != undefined
                  ">
                    <a :href="baseURL + item.AttachPath" download target="_blank" @click="changeDocStatus(item)">
                      <vs-avatar color="primary" icon-pack="feather" icon="icon-download-cloud"
                        style="background: primary" /></a>
                    <p style="
                            color: #ffffff;
                            font-size: 12px;
                            opacity: 1;
                            line-height: 30px;
                          ">
                      {{ $t("Download") }}
                    </p>
                  </div>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </vx-card>

  </div>
</template>
<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
export default {
  components: {
    imageLazy,
  },

  data() {
    return {
      baseURL: domain,
      Model: [],
    };
  },
  props: {
    patientDoc: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        debugger;
        return this.$refs.table.currentx;
      }
      return 0;
    },
    doctorMedical() {
      return this.$store.state.DoctorList.patientReservation;
    },
  },
  methods: {
    changeDocStatus(Doc) {
      debugger;
      Doc;
      debugger;
      var model = {};
      model.doctorID = Doc.DoctorID;
      model.patientID = Doc.PatientID;
      model.PatientReservationSessionID = Doc.PatientReservationID;

      model.ID = Doc.ID;
      model.DocumentTypeID = Doc.DocumentTypeID;

      debugger;
      this.$store
        .dispatch("DoctorList/ChangeDocStatus", model)
        .then((res) => {
          if (res.status == 200) {
            debugger;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.deleteData,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    searchData() {
      debugger;
      if (this.patientDoc.PatientID) {
        if (this.patientDoc.DoctorID) {
          debugger;

          var model = {};
          // model.PatientReservationSessionID = this.$route.params.PatientReservationID;
          model.doctorID = this.patientDoc.DoctorID;
          model.patientID = this.patientDoc.PatientID;
          model.PatientReservationSessionID = this.patientDoc.PatientReservationSessionID;
          debugger;
          this.$store
            .dispatch(
              "DoctorList/PatientMedicalReportRequestDownloadDoc",
              model
            )
            .then((res) => {
              if (res.status == 200) {
                debugger;
                this.Model = res.data.Data;
                if (this.Model == null || this.Model.length == 0) {
                  this.$vs.notify({
                    title: this.$t("NoData"),
                    text: this.$t("NoDataToshow"),
                    iconPack: "feather",
                    icon: "icon-alert-circle",
                    color: "warning",
                  });
                }
                debugger;
              }
            })
            .catch(() => {
              this.$vs.loading.close();
            });
        }
      }
    },
  },
  created() {
    debugger;
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    debugger;
    this.searchData();
  },
};
</script>
<style>
#PMReportDownload table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
  background: white;
}

#PMReportDownload th,
#PMReportDownload td {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
  border-collapse: collapse;
  text-align: center;
  font: normal normal normal 20px/25px Futura PT;
}

#PMReportDownload .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}

#PMReportDownload .login-tabs-container {
  min-height: 505px;
}

#PMReportDownload .con-tab {
  padding-bottom: 14px;
}

#PMReportDownload .con-slot-tabs {
  margin-top: 1rem;
}

#PMReportDownload .background {
  color: rgb(255, 9, 9);
}

#PMReportDownload .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}

#PMReportDownload .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}

[dir] #PMReportDownload th,
[dir] #PMReportDownload td {
  border: 1px solid #cfcfcf !important;
}
</style>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>